import spaPages from './spa-pages.json';

function isHomePage(path) {
    return path === '/' || path.toLowerCase() === '/home';
}

function generateStaticPage(path) {
    if (isHomePage(path)) {
        return { brand: 'as', 'primary-section': 'home', type: 'home' };
    }

    const sections = path
        .split('/')
        .map(s => s?.toLowerCase().replaceAll('.html', '').replaceAll('-', ' '))
        .filter(s => s.length > 0 && s !== 'index');

    const [first, second, ...rest] = sections;
    const firstSection = first ? { 'primary-section': first } : undefined;
    const secondSection = second ? { 'secondary-section': second } : undefined;
    const thirdSection = rest.length ? { 'tertiary-section': rest.join(': ') } : undefined;

    return {
        brand: 'as',
        type: first,
        ...firstSection,
        ...secondSection,
        ...thirdSection,
    };
}

function generateSearchPage() {
    return {
        brand: 'as',
        'primary-section': 'search',
        type: 'Search',
    };
}

function generateLicenseSigningPage(pageName) {
    const thirdSection = pageName ? { 'tertiary-section': pageName?.toLowerCase() } : undefined;
    return {
        brand: 'as',
        'primary-section': 'license submission',
        'secondary-section': 'my articles',
        type: 'license submission',
        ...thirdSection,
    };
}

function generateSPAPage(pageName = '') {
    return spaPages[pageName];
}

function generateLoginPage() {
    return {
        brand: 'as',
        'primary-section': 'login',
        type: 'authentication',
    };
}

export {
    isHomePage,
    generateLicenseSigningPage,
    generateLoginPage,
    generateSPAPage,
    generateSearchPage,
    generateStaticPage,
};
