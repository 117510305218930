import _ from 'underscore';

const Method = function () {};
Method.prototype = {
    is(str) {
        // eslint-disable-next-line eqeqeq
        return this == str;
    },
    not(str) {
        return !this.is(str);
    },
    toString() {
        return this.key;
    },
};

const method = {
    CREDIT_CARD: _.extend(new Method(), {
        isAllowedChange(str) {
            return !str || str.length === 0 || method.INVOICE.is(str);
        },
        key: 'CREDIT_CARD',
        logo: true,
        text: 'Credit card',
    }),
    INVOICE: _.extend(new Method(), {
        isAllowedChange(str) {
            return !str || str.length === 0 || method.INVOICE.is(str);
        },
        key: 'INVOICE',
        logo: false,
        text: 'Invoice',
    }),
    PROFORMA: _.extend(new Method(), {
        isAllowedChange(str) {
            return !str || str.length === 0;
        },
        key: 'PROFORMA',
        logo: false,
        text: 'Proforma',
    }),
    SOCOPAY: _.extend(new Method(), {
        isAllowedChange(str) {
            return !str || str.length === 0 || method.SOCOPAY.is(str);
        },
        key: 'SOCOPAY',
        logo: true,
        text: 'Alipay',
    }),
    WAIVED: _.extend(new Method(), {
        isAllowedChange(str) {
            return !str || str.length === 0 || method.WAIVED.is(str);
        },
        key: 'WAIVED',
        logo: true,
        text: 'Waived',
    }),
};
method.all = [method.CREDIT_CARD, method.INVOICE, method.PROFORMA, method.SOCOPAY];

function getMethodByKey(key) {
    return _.find(method, function (v) {
        // eslint-disable-next-line eqeqeq
        return v == key;
    });
}

export default {
    getMethodByKey,
    method,
    oaPaymentMethods: [method.CREDIT_CARD, method.INVOICE, method.PROFORMA], // don't replace by all, it will not be obvious what methods are available
    oaPaymentMethodsWithAlipay: [method.CREDIT_CARD, method.INVOICE, method.PROFORMA, method.SOCOPAY],
    oaTransitions: {
        CREDIT_CARD: method.all,
        INVOICE: method.all,
        PROFORMA: method.all,
    },
    oaTransitionsWithAlipay: {
        CREDIT_CARD: method.all,
        INVOICE: method.all,
        PROFORMA: method.all,
        SOCOPAY: method.all,
    },
    ooPaymentMethods: [method.CREDIT_CARD, method.INVOICE],
    ooPaymentMethodsWithAlipay: [method.CREDIT_CARD, method.INVOICE, method.SOCOPAY],
    // TODO is not used any more, remove
    ooTransitions: {
        CREDIT_CARD: [],
        INVOICE: [method.CREDIT_CARD, method.INVOICE],
        PROFORMA: [],
    },
};
