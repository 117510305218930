import React, { useEffect, useState } from 'react';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import InfoLabel from 'app/blocks/InfoLabel/InfoLabel';
import * as middleWareDashBoard from 'app/blocks/middleware/dashboard';
import Modal from 'app/blocks/Modal/Modal';
import { ItemTitle } from 'app/blocks/titles/titles';
import { Input } from 'app/ui/form';
import './ModalArticleShare.scss';

type Props = {
    doi: string;
    l: l;
    onClose: () => void;
};

function ModalArticleShare({ doi, l, onClose }: Props): React.ReactElement {
    const [shareLink, setShareLink] = useState(null);
    const [literatumLink, setLiteratumLink] = useState(true);
    const [isCopyDisabled, setIsCopyDisabled] = useState(true);
    let shareInput = null;

    useEffect(() => {
        (async () => {
            const { literatumUrl, shareUrl } = await middleWareDashBoard.getShareUrl(doi);
            setShareLink(shareUrl);
            setLiteratumLink(literatumUrl);
            setIsCopyDisabled(false);
        })();
    }, [setShareLink, setIsCopyDisabled, doi, setLiteratumLink]);

    const onCopy = () => {
        shareInput.select();
        shareInput.setSelectionRange(0, 99999); // for mobile compatibility
        window.document.execCommand('copy');
    };

    const message = l('DASHBOARD.ARTICLE_SHARE.MODAL.text');
    const title = l('DASHBOARD.ARTICLE_SHARE.MODAL.title');
    const copyBtnLabel = l('DASHBOARD.ARTICLE_SHARE.MODAL.button_text');
    const helperText = l('DASHBOARD.ARTICLE_SHARE.MODAL.helper_text');

    return (
        <Modal onClose={onClose} type="ModalArticleShare">
            <div className="ModalArticleShare modal-body Dialog-Body" data-seleniumid="modal-body-article-share">
                <ItemTitle data-seleniumid="modal-article-share-title">{title}</ItemTitle>
                <div className="Text" dangerouslySetInnerHTML={{ __html: message }} />
                <div className="Actions">
                    <Input
                        ref={input => {
                            shareInput = input;
                        }}
                        className="Input"
                        data-seleniumid="article-share-link-field"
                        disabled={isCopyDisabled}
                        id="article-share-link"
                        placeholder="Loading..."
                        type="text"
                        value={shareLink}
                    />
                    <PrimaryButton
                        data-seleniumid="article-share-copyBtn"
                        disabled={isCopyDisabled}
                        name="button"
                        onClick={onCopy}
                        type="button"
                    >
                        {copyBtnLabel}
                    </PrimaryButton>
                </div>
                {!literatumLink && (
                    <InfoLabel className="helperText" seleniumId="modal-body-article-share-info-text">
                        {helperText}
                    </InfoLabel>
                )}
            </div>
        </Modal>
    );
}

export default withCodes2(ID.DASHBOARD)(ModalArticleShare);
