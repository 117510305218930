import versions from 'app/blocks/ajax/config/versions';

function isMock(): boolean {
    return (
        versions.ENV === 'local' ||
        versions.ENV === 'kuber-mock' ||
        versions.ENV === 'docker' ||
        versions.ENV === 'docker,mock'
    );
}

function isNonProd(): boolean {
    return versions.ENV !== 'aws-wprod';
}

export { isMock, isNonProd };
