import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import DialogMessage from 'app/blocks/dialog/dialog__message';
import Modal from 'app/blocks/Modal/Modal';
import routes from 'app/pages/routes';
import './OutdatedDashboardModal.scss';

class OutdatedDashboardModal extends React.PureComponent<{ l: l; onClose: () => void }, { timeout: number }> {
    static propTypes = {
        l: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    state = { timeout: 60 };

    interval;

    componentDidMount() {
        this.interval = setInterval(() => {
            if (this.state.timeout === 0) {
                clearInterval(this.interval);
                this.onGoDashboardClick();
            } else {
                this.setState(state => ({ timeout: state.timeout - 1 }));
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    onGoDashboardClick = () => {
        this.props.onClose();
        if (this.isOnDashboard()) {
            routes.reload();
        } else {
            routes.navigateToDashboard();
        }
    };

    isOnDashboard() {
        return window.location.href.includes('dashboard');
    }

    render() {
        const { l, onClose } = this.props;
        const { timeout } = this.state;

        return (
            <Modal
                className="Dialog OutdatedDashboardModal"
                hideCloseButtonInHeader
                ignoreBackdropClicks
                ignoreEsc
                onClose={onClose}
                type="outdated-dashboard"
            >
                <div className="modal-body Dialog-Body OutdatedDashboardModal-Content" data-seleniumid="modal-body">
                    <DialogMessage
                        html={l('ERROR.ROUTING_SERVICE_POSSIBLE_OUTDATED_DATA', { time: `${timeout} sec(s)` })}
                        type="error"
                    />
                    <div className="Dialog-BodyActions">
                        <Button
                            data-seleniumid="outdatedDashboardModal-dialog-close-button"
                            onClick={this.onGoDashboardClick}
                        >
                            {this.isOnDashboard() ? l('BUTTONS.RELOAD_DASHBOARD') : l('BUTTONS.GO_TO_DASHBOARD')}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

function showOutdatedDashboardModal() {
    showDialog.modal(closeAnd => ({
        onClose: closeAnd(),
        type: withCodes(OutdatedDashboardModal, ID.BUTTONS, ID.DIALOG, ID.ERROR),
    }));
}

export { OutdatedDashboardModal, showOutdatedDashboardModal };
