import cn from 'classnames';
import React, { HTMLInputTypeAttribute } from 'react';
import { normalizeInputValue } from 'app/blocks/common/utils';

function withInputValue(func) {
    return func ? ({ target: el }) => func(el.type === 'checkbox' ? el.checked : el.value) : undefined;
}

type Props = {
    isError?: boolean;
    type?: 'textarea' | HTMLInputTypeAttribute;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<any, Props>(({ className, isError = false, type, ...props }, ref) => {
    const patch: Record<string, any> = {
        className: cn('form-control', className, { error: isError }),
    };

    if (Object.prototype.hasOwnProperty.call(props, 'value')) {
        // do not break uncontrolled inputs
        patch.value = normalizeInputValue(props.value);
    }

    return type === 'textarea' ? (
        // @ts-ignore
        <textarea ref={ref} {...props} {...patch} />
    ) : (
        <input ref={ref} type={type} {...props} {...patch} />
    );
});

const Input2 = React.forwardRef<any, Props>((props, ref) => {
    const patch = {
        onBlur: withInputValue(props.onBlur),
        onChange: withInputValue(props.onChange),
    };

    return <Input ref={ref} {...props} {...patch} />;
});

export { Input, Input2 };
