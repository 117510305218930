import React, { useState } from 'react';
import Image from 'app/blocks/image/image';
import PanelActionsButtons from 'app/blocks/Panel/actions/buttons/panel_actions__buttons';
import { ActionButton } from 'app/blocks/Panel/actions/buttons/panel_actions__buttons.utils';

const MenuJournal: React.VFC<{
    close: () => void;
    item: Search.Journal;
}> = ({ close, item }) => {
    const [actions, setActions] = useState(item.doc.actions);

    const actionsHandler = (button: ActionButton) => {
        if (button.toggle) {
            const newActions = actions.map(action => {
                if (action.code === button.code) {
                    return { code: button.toggle };
                }

                return action;
            });

            setActions(newActions);
        } else {
            close();
        }
    };

    return (
        <div className="menu__search_Item clearfix">
            <div className="menu__search_Item__text">
                <div className="menu__search-item-pic">
                    <Image className="menu__search_Item__img" src={item.doc.coverImage} type={Image.TYPES.JOURNAL} />
                </div>
                <div className="text_bold text_md" dangerouslySetInnerHTML={{ __html: item.doc.title }} />
                {item.doc.displayFormerTitle && (
                    <div className="search_journal_former_name">
                        <span>Formerly known as &quot;</span>
                        <span dangerouslySetInnerHTML={{ __html: item.doc.displayFormerTitle }} />
                        <span>&quot;</span>
                    </div>
                )}
                <div className="p-top_micro">
                    <span className="search_Item_field" data-seleniumid={`search-results-journal-eissn-${item.id}`}>
                        <span>Online ISSN: </span>
                        <span className="search_Item_value">{item.doc.electronicIssn}</span>
                    </span>
                </div>
                <div className="p-top_micro">
                    <span className="search_Item_field" data-seleniumid={`search-results-journal-pissn-${item.id}`}>
                        <span>Print ISSN: </span>
                        <span className="search_Item_value">{item.doc.printIssn}</span>
                    </span>
                </div>
            </div>
            {actions && actions.length > 0 && (
                <PanelActionsButtons
                    className="pull-right text-right"
                    classNameButton="menu__search_Item__actions"
                    handler={actionsHandler}
                    inline
                    items={actions}
                    name="search"
                    params={{
                        AUTHOR_GUIDELINES_URL: item.doc.authorGuidelinesUrl,
                        JOURNAL_ID: item.id,
                        SUBMIT_ARTICLE_URL: item.doc.onlineSubmissionUrl,
                    }}
                    seleniumid="menu-search--journal"
                />
            )}
        </div>
    );
};

export default MenuJournal;
