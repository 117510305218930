import moment from 'moment';
import { l } from 'app/blocks/common/codes';
import { toPriceListFormat } from 'app/blocks/common/getCurrencySymbol';
import { compactObject } from 'app/blocks/common/utils';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import payment from 'app/pages/orders/orders.payment';

const stepStatus = {
    COMPLETED: 'completed',
    INITIATED: 'initiated',
    PROGRESS: 'progress',
};

const getStepStatus = isConfirmed => (isConfirmed ? stepStatus.COMPLETED : stepStatus.PROGRESS);

const getPublicationStatus = statusCode => statusCode.split('_').join(' ').toLowerCase();

const format = date => moment(date).format('D MMMM YYYY');

const getHistory = history => {
    const result = {};
    const submitted = history.find(e => e.status === 'SUBMITTED_DATE');
    const accepted = history.find(e => e.status === 'ACCEPTED_DATE');

    if (submitted) {
        result['publication-submitted'] = format(submitted.date);
    }

    if (accepted) {
        result['publication-accepted'] = format(accepted.date);
    }
    return result;
};

function getFundersGrantDetails(data) {
    if (data.items) {
        const fundersGrantDetails = data.items.map(item => {
            return {
                'funder-id': item.researchFunder.id,
                'funder-name': item.researchFunder.name,
                'grant-numbers':
                    item.grants && item.grants.length ? item.grants.map(grant => grant.grantNumber) : 'no grant number',
            };
        });
        return { 'funder-info': fundersGrantDetails };
    }
    return undefined;
}

function getOpenAccessOptions(data) {
    const { authorSelectedOnlineOpen } = data;

    if (authorSelectedOnlineOpen) {
        return { 'open-access': 'open access' };
    }
    return { 'open-access': 'subscription' };
}

function getOpenAccessCoverage(data) {
    const { answerState, reasonCode, reasonText } = data;
    return compactObject({ 'answer-state': answerState, 'reason-code': reasonCode, 'reason-text': reasonText });
}

function getAffiliations(data) {
    if (data.affiliations) {
        const alliliationInfo = data.affiliations.map(affiliation => {
            return {
                'affiliation-id': affiliation.affiliationId,
                'affiliation-name': affiliation.name || affiliation.institutionName,
                status:
                    !affiliation.confirmed || affiliation.submissionData || affiliation.custom
                        ? 'not verified'
                        : 'verified',
            };
        });
        return { 'affiliations-info': alliliationInfo };
    }
    return undefined;
}

function getCopyrightInfo(data) {
    const copyrightOwnershipOptions = {
        COMPANY_INSTITUTION: 'company/institution-owned work',
        CONTRIBUTOR: 'contributor-owned work',
        NGO: 'non-governmental organization work',
        OTHER_GOVT: 'other government work',
        UK_GOVT: 'u.k. government work',
        US_GOVT: 'u.s. government work',
    };
    const { copyrightOwnership, copyrightOwnershipSubtype } = data;
    let copyrightOwnershipInfo = copyrightOwnershipOptions[copyrightOwnership]
        ? copyrightOwnershipOptions[copyrightOwnership]
        : undefined;
    if (copyrightOwnershipInfo && copyrightOwnershipSubtype) {
        copyrightOwnershipInfo += ` - all authors are employees of: ${copyrightOwnershipSubtype}`;
    }
    return copyrightOwnershipInfo ? { 'copyright-info': copyrightOwnershipInfo } : undefined;
}

function getLicenseTypes(data) {
    const { licenseType } = data;
    if (licenseType && licenseType.value) {
        return { 'license-agreement-type': l(`LICENSE.${licenseType.value}.shortName`).toLowerCase() };
    }
    return undefined;
}

function getPaymentInfo(data) {
    const paymentDetails = data?.onlineOpenOrder?.paymentDetails;
    const pricingDetails = data?.onlineOpenOrder?.pricingDetails;
    const chinaPayPricingDetails = data?.onlineOpenOrder?.chinaPayPricingDetails;

    if (!paymentDetails || !pricingDetails || !chinaPayPricingDetails) {
        return undefined;
    }

    const { paymentMethod } = paymentDetails;
    const { basePrice, currency, discountAmount, taxAmount } = pricingDetails;

    if (paymentMethod === payment.method.SOCOPAY.key) {
        const { conversionRate, currency: aliPayCurrency, surcharge, tax } = chinaPayPricingDetails;
        return {
            currency: aliPayCurrency,
            discount: +toPriceListFormat(+discountAmount * +conversionRate),
            'payment-method': paymentMethod?.toLowerCase(),
            'publication-charge': +toPriceListFormat(+basePrice * +conversionRate),
            tax: +toPriceListFormat(+tax + +surcharge),
        };
    }

    return {
        currency,
        discount: +toPriceListFormat(discountAmount),
        'payment-method': paymentMethod?.toLowerCase(),
        'publication-charge': +toPriceListFormat(basePrice),
        tax: +toPriceListFormat(taxAmount),
    };
}

function getUpdatedStepInfo(data) {
    const { id } = data;
    switch (id) {
        case StepEnum.FUNDERS:
            return getFundersGrantDetails(data);
        case StepEnum.OPEN_ACCESS_OPTIONS:
            return getOpenAccessOptions(data);
        case StepEnum.OPEN_ACCESS_COVERAGE:
            return getOpenAccessCoverage(data);
        case StepEnum.AFFILIATIONS:
            return getAffiliations(data);
        case StepEnum.COPYRIGHT:
            return getCopyrightInfo(data);
        case StepEnum.LICENSE_TYPES:
            return getLicenseTypes(data);
        case StepEnum.PAYMENT:
            return getPaymentInfo(data);
        default:
            return undefined;
    }
}

function generateLicenseSigning(data = {}) {
    const { article, history, journal, statusCode, step } = data;
    const result = {
        'article-doi': article?.doi,
        'article-title': article?.name,
        'article-type': article?.type,
        'internal-article-id': article?.id,
        'journal-id': journal?.id,
        'journal-title': journal?.name,
    };

    if (step) {
        const { data: stepData, isConfirmed, name, substep } = step;
        const substepInfo = substep ? { substep } : null;
        const stepInfo = { step: { name, status: getStepStatus(isConfirmed), ...substepInfo } };
        let additionalInfo;
        if (stepData && isConfirmed) {
            additionalInfo = getUpdatedStepInfo(stepData);
        }
        return { ...result, ...stepInfo, ...additionalInfo };
    }

    if (statusCode && history) {
        return { ...result, ...getHistory(history), 'publication-status': getPublicationStatus(statusCode) };
    }
    return result;
}

export default generateLicenseSigning;
