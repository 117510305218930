export const KeyCodes = {
    ALT: 18,
    BACKSPACE: 8,
    CAPS_LOCK: 20,
    COMMAND: 91,
    CTRL: 17,
    DELETE: 46,
    DOWN: 40,
    END: 35,
    ENTER: 13,
    ESC: 27,
    HOME: 36,
    INSERT: 45,
    LEFT: 37,
    LEFT_COMMAND: 91,
    PAGE_DOWN: 34,
    PAGE_UP: 33,
    PAUSE_BREAK: 19,
    RIGHT: 39,
    RIGHT_COMMAND: 93,
    SHIFT: 16,
    SPACE: 32,
    TAB: 9,
    UP: 38,
};

export function getAddressString({ city, country, countryCode, countryName, stateCode, stateName }) {
    return [city, stateName || stateCode, countryCode || countryName || country].filter(item => item).join(', ');
}
