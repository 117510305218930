import React from 'react';
import { checkChanges, removeNavigationListener, setNavigationListener } from 'app/app.router.navigation-handling';
import { Button, PrimaryButton } from 'app/blocks/buttons/buttons';
import { l } from 'app/blocks/common/codes';
import DialogMessage from 'app/blocks/dialog/dialog__message';
import Modal from 'app/blocks/Modal/Modal';
import { confirmType } from 'app/blocks/Modal/utils';

function ConfirmLeavingModal({ onApprove, onReject }) {
    return (
        <Modal className="Dialog" id="confirmLeavingModal" ignoreBackdropClicks onClose={onReject} type="confirmation">
            <div className="modal-body Dialog-Body" data-seleniumid="modal-body">
                <DialogMessage html={l('DIALOG.CONFIRM_LEAVING.TEXT')} type="confirmation" />
                <div className="Dialog-BodyActions Dialog-BodyActions-Column">
                    <PrimaryButton data-seleniumid="confirmation-dialog-cancel-button" onClick={onReject}>
                        {l('DIALOG.CONFIRM_LEAVING.CANCEL')}
                    </PrimaryButton>
                    <Button data-seleniumid="confirmation-dialog-confirm-button" onClick={onApprove}>
                        {l('DIALOG.CONFIRM_LEAVING.CONFIRM')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

function ConfirmLogoutModal({ onApprove, onReject }) {
    return (
        <Modal className="Dialog" id="confirmLeavingModal" ignoreBackdropClicks onClose={onReject} type="confirmation">
            <div className="modal-body Dialog-Body" data-seleniumid="modal-body">
                <DialogMessage html={l('DIALOG.CONFIRM_LOGOUT.TEXT')} type="confirmation" />
                <div className="Dialog-BodyActions Dialog-BodyActions-Column">
                    <PrimaryButton data-seleniumid="confirmation-dialog-cancel-button" onClick={onReject}>
                        {l('DIALOG.CONFIRM_LOGOUT.CANCEL')}
                    </PrimaryButton>
                    <Button data-seleniumid="confirmation-dialog-confirm-button" onClick={onApprove}>
                        {l('DIALOG.CONFIRM_LOGOUT.CONFIRM')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

async function onLeavePage(
    allowNavigation: Parameters<Parameters<typeof setNavigationListener>[0]['askUserForAction']>[0],
) {
    allowNavigation(await confirmType(ConfirmLeavingModal));
}

async function confirmLogout() {
    return !checkChanges() || confirmType(ConfirmLogoutModal);
}

export { confirmLogout, onLeavePage };
export default function withConfirmLeaving(Component) {
    return function ConfirmLeaving(props) {
        React.useEffect(() => () => removeNavigationListener(), []);

        const setChangesChecker = (fn, askUserForAction = undefined) => {
            setNavigationListener({
                askUserForAction: askUserForAction || onLeavePage,
                checkUnsavedChanges: fn,
            });
        };

        return (
            <Component
                {...props}
                setChangesChecker={setChangesChecker}
                unsetChangesChecker={() => removeNavigationListener()}
            />
        );
    };
}
