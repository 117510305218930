import React from 'react';
import { KeyCodes } from 'app/ui/as-ui-components/utils';

type KeyboardEventHandler = (event: KeyboardEvent | React.KeyboardEvent) => void;

function getKeyIntValueFromEvent(event: KeyboardEvent | React.KeyboardEvent): number {
    const { charCode, keyCode } = event;
    return keyCode || charCode || 0;
}

function eventContainsKey(event: KeyboardEvent | React.KeyboardEvent, keyCode: number): boolean {
    return getKeyIntValueFromEvent(event) === keyCode;
}

function onKeyPress(keyCode: number, callback: KeyboardEventHandler): KeyboardEventHandler {
    return event => {
        if (eventContainsKey(event, keyCode)) {
            callback(event);
        }
    };
}

function onEnterKeyPress(callback: KeyboardEventHandler): KeyboardEventHandler {
    return onKeyPress(KeyCodes.ENTER, callback);
}

export { KeyCodes as KeyCode, eventContainsKey, onKeyPress, onEnterKeyPress };
