/* eslint-disable */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import onClickOutside from 'react-onclickoutside';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import { ID, withCodes } from 'app/blocks/common/codes';
import { KeyCodes } from '../utils';
import EnteredValueItem from './EnteredValueItem';
import './combobox.scss';

const LOAD_LEADING_ITEM_ON_INPUT = false;
const DEFAULT_THROTTLE_WAIT_FETCH = 500;
const DEFAULT_DISPLAYING_PROP = 'name';
const DEFAULT_PAGE_SIZE = 10;

class Combobox extends React.Component {
    constructor(props) {
        super(props);
        this.props.comboboxInstance(this);

        const { displayingProp } = this.props;

        const nullItemId = this.props.nullItemId == null ? null : this.props.nullItemId;
        this.nullItem = { id: nullItemId };
        this.nullItem[displayingProp] = this.props.nullItemName;
        this.undefinedItem = {};
        this.undefinedItem[displayingProp] = '';

        const selectedItem = this.checkAndWrapItemToNullObjects(this.props.selectedItem);

        this.fetchItemsList = _.throttle(
            params => {
                this._fetchItemsList(params);
            },
            this.props.throttleWaitFetch,
            { leading: LOAD_LEADING_ITEM_ON_INPUT },
        );

        this.state = {
            isOpen: this.props.isOpen || false,
            isTyping: false,
            sNode: null,
            isScroll: false,
            lastRequest: null,
            itemsList: [],
            selectedItem,
            inputValue: selectedItem[displayingProp],
            displayingProp,
            pageSize: this.props.pageSize,
            firstPageQueryTime: 0,
            sIndex: -1,
            firstPageQueryString: '',
            isLoadingNextPage: false,
            errorLoading: false,
            style: this.props.style || {},
            uid: uuidv4(),
        };
    }

    handleClickOutside(e) {
        if (this.state.isOpen) {
            this.props.onBlur && this.props.onBlur(e);
            this.close();
            this.refs.input.blur();
        }
    }

    allItems() {
        return (this.props.hideNoneValue ? [] : [this.nullItem]).concat(this.state.itemsList);
    }

    checkAndWrapItemToNullObjects(selectedItem) {
        let targetItem = selectedItem;
        if (
            selectedItem === this.nullItem.id ||
            (selectedItem && selectedItem.id === this.nullItem.id && !selectedItem.custom)
        ) {
            targetItem = this.nullItem;
        } else if (selectedItem === undefined) {
            targetItem = this.undefinedItem;
        }

        return targetItem;
    }

    componentWillReceiveProps(nextProps) {
        const next = nextProps.selectedItem;
        const prev = this.props.selectedItem;

        const selectedItem = this.checkAndWrapItemToNullObjects(next === null ? undefined : next);

        const skipSelection =
            next === prev ||
            (next && prev && next.id === prev.id && next.name === prev.name && next.custom === prev.custom);

        if (!skipSelection) {
            this.selectItem(selectedItem);
        }

        if (typeof nextProps.isOpen === 'boolean') {
            this.setState({ isOpen: nextProps.isOpen });
        }
    }

    getFirstPage(value) {
        this.setState({ itemsList: [], isLoadingNextPage: true, firstPageQueryString: value });
        this.fetchItemsList({ searchStr: value, append: false });
    }

    onRequestNextPage = () => {
        this.fetchItemsList({ searchStr: this.state.firstPageQueryString, append: true });
    };

    lastRequestIndex = 0;

    lastResponseIndex = 0;

    _fetchItemsList = args => {
        const { searchStr } = args;
        const { getListDataPromise } = this.props;
        const offset = args.append ? this.state.itemsList.length : 0;
        const requestPageSize = this.state.pageSize + 1;

        if (getListDataPromise) {
            this.setState({
                isLoadingNextPage: true,

                errorLoading: false,
                errorMessage: null,
                message: null,
            });
            const requestIndex = ++this.lastRequestIndex;
            getListDataPromise(searchStr, requestPageSize, offset)
                .then(loadedItemsList => {
                    const { itemsList, pageSize } = this.state;
                    let { sIndex } = this.state;
                    if (this.lastResponseIndex <= requestIndex) {
                        this.lastResponseIndex = requestIndex;
                        const itemsWithoutGroupsCount = loadedItemsList.filter(item => !item.isGroup).length;
                        let hasNextPage = false;
                        if (itemsWithoutGroupsCount > pageSize) {
                            loadedItemsList.splice(loadedItemsList.length - 1, 1);
                            hasNextPage = true;
                        }

                        let newItemsList = loadedItemsList;
                        if (args.append) {
                            newItemsList = itemsList.concat(newItemsList);
                        } else {
                            sIndex = -1;
                        }

                        this.setState(
                            {
                                itemsList: newItemsList,
                                isLoadingNextPage: this.lastRequestIndex > requestIndex,
                                hasNextPage,
                                isSelectedEnteredValue: false,
                                sIndex,
                            },
                            () => this.scrollItemIntoViewIfNeeded(this.state.sIndex),
                        );
                    }
                })
                .catch(error => {
                    if (this.props.error) {
                        this.setState({
                            message: this.props.error,
                            isLoadingNextPage: this.lastRequestIndex > requestIndex,
                            isSelectedEnteredValue: false,
                        });
                        console.error(error && error.message);
                        return;
                    }
                    this.close();
                    this.setState({
                        isLoadingNextPage: this.lastRequestIndex > requestIndex,
                        isSelectedEnteredValue: false,
                        errorLoading: true,
                        errorMessage: error && error.message,
                    });
                });
        } else {
            console.warn('getting items is not defined for Combobox');
        }
    };

    clear() {
        this.setState({ inputValue: '' });
    }

    toggle = () => {
        this.state.isOpen ? this.close() : this.open();
    };

    isValidToSearch = value => {
        const { toggleOnChange, requestMinLength } = this.props;

        if (!toggleOnChange) {
            return true;
        }

        return value && (!requestMinLength || (requestMinLength && value.length >= requestMinLength));
    };

    open = e => {
        if (this.props.disabled || this.state.isOpen) {
            return;
        }

        const storedValue = e && e.target && e.target.value;
        if (this.props.toggleOnChange && !this.isValidToSearch(storedValue)) {
            return;
        }

        this.changeIsOpen(true);

        const searchQuery = this.state.selectedItem === this.nullItem ? '' : storedValue;

        this.getFirstPage(searchQuery);
    };

    close(clearInput) {
        if (this.props.disabled || !this.state.isOpen) {
            return;
        }

        this.changeIsOpen(false);

        if (!this.props.notRestorePrevious && !clearInput) {
            const previousSelectedInputValue = this.state.selectedItem[this.state.displayingProp];
            this.setState({ inputValue: previousSelectedInputValue });
        }
        if (clearInput) {
            this.setState({ inputValue: '', firstPageQueryString: '' });
        }
    }

    changeIsOpen(isOpen) {
        if (_.isEqual(this.state.isOpen, isOpen)) {
            return;
        }

        this.props.toggleIsOpen ? this.props.toggleIsOpen() : this.setState({ isOpen });
    }

    selectItem = (item, notifyChange) => {
        const stateItem = this.checkAndWrapItemToNullObjects(item);
        const { displayingProp } = this.state;

        if (!(stateItem && stateItem.externalClick)) {
            this.setState({
                selectedItem: stateItem,
                inputValue: this.props.emptyValueOnSelect ? this.state.inputValue : stateItem[displayingProp],
                isOpen: this.props.toggleIsOpen ? this.state.isOpen : false,
                firstPageQueryString: this.props.emptyValueOnSelect ? this.state.firstPageQueryString : '',
                isTyping: false,
            });
        }

        if (notifyChange) {
            this.props.changeHandler(stateItem == this.nullItem && this.props.nullItemId == null ? null : item);
        }
    };

    selectText(text) {
        this.selectItem(
            {
                name: this.props.enteredValueMapper(text),
                custom: true,
            },
            true,
        );
    }

    onInputBlur = () => {
        if (this.props.disabled || this.state.isOpen) {
            return;
        }

        const { inputValue } = this.state;
        const { displayingProp } = this.state;
        const selectedItemName = this.state.selectedItem[displayingProp];

        if (selectedItemName && selectedItemName !== inputValue) {
            this.setState({ inputValue: selectedItemName });
        }
    };

    changeInputHandler = e => {
        let inputValue = e.target.value;
        if (inputValue === '') {
            inputValue = undefined;
        }

        const { inputValue: prevInputValue, lastRequest, itemsList } = this.state;

        const newStateValues = {
            isTyping: true,
            inputValue,
            lastRequest: inputValue,
            firstPageQueryString: inputValue,
            firstPageQueryTime: e.timeStamp,
        };
        this.setState(newStateValues);

        // fix for ie explicit request
        if (
            !_.isUndefined(inputValue) ||
            !_.isUndefined(prevInputValue) ||
            (_.isUndefined(lastRequest) && itemsList.length === 0)
        ) {
            if (this.isValidToSearch(inputValue)) {
                this.getFirstPage(inputValue);
            }
        }

        if (this.props.toggleOnChange) {
            if (this.isValidToSearch(inputValue)) {
                this.open(e);
            } else {
                this.close();
            }
        }
    };

    scrollToDOMElement(element) {
        if (element) {
            const panel = element.parentNode;
            if (
                element.offsetTop + element.offsetHeight > panel.scrollTop + panel.offsetHeight ||
                element.offsetTop < panel.scrollTop
            ) {
                panel.scrollTop = element.offsetTop - panel.offsetTop;
            }
        }
    }

    scrollItemIntoViewIfNeeded(sIndex) {
        this.scrollToDOMElement(ReactDOM.findDOMNode(this.refs[`item_${sIndex}`]));
    }

    onScroll = () => {
        const element = this.refs.fakeLoadingIndicator;
        const container = this.refs.overlayList;

        if (!element) {
            return;
        }

        if (container.scrollTop > container.scrollHeight - container.offsetHeight - element.offsetHeight) {
            this.onRequestNextPage();
        }
    };

    keyDown = e => {
        const { isOpen, hasNextPage, isLoadingNextPage, inputValue } = this.state;
        let { sIndex, isSelectedEnteredValue } = this.state;
        const { onEnter } = this.props;
        const itemsLength = this.allItems().length;

        if (onEnter && e.keyCode === KeyCodes.ENTER && sIndex === -1) {
            e.preventDefault();
            onEnter();
            this.refs.input.blur();
        } else if (isOpen) {
            if (e.keyCode === KeyCodes.TAB || e.keyCode === KeyCodes.ESC) {
                this.close();
            } else if (e.keyCode === KeyCodes.UP) {
                e.preventDefault();
                if (isSelectedEnteredValue) {
                    isSelectedEnteredValue = false;
                    sIndex = itemsLength - 1;
                } else {
                    sIndex = this.prevSelect(sIndex);
                }
                this.scrollItemIntoViewIfNeeded(sIndex >= 0 ? sIndex : 0);

                this.setState({
                    isSelectedEnteredValue,
                    sIndex,
                });
            } else if (e.keyCode === KeyCodes.DOWN) {
                e.preventDefault();
                if (sIndex < itemsLength - 1 && itemsLength > 0) {
                    sIndex = this.nextSelect(sIndex);
                } else if (this.isShowEnteredValue() && !isSelectedEnteredValue && !isLoadingNextPage) {
                    isSelectedEnteredValue = true;
                    this.scrollToDOMElement(ReactDOM.findDOMNode(this.refs.enteredValueItem));
                } else if (hasNextPage && !isLoadingNextPage) {
                    this.onRequestNextPage();
                }

                this.scrollItemIntoViewIfNeeded(sIndex);

                this.setState({
                    sIndex,
                    isSelectedEnteredValue,
                });
            } else if (e.keyCode === KeyCodes.ENTER) {
                e.preventDefault();

                if (isSelectedEnteredValue) {
                    this.selectText(inputValue);
                } else {
                    const items = this.allItems();
                    if (sIndex >= 0 && sIndex < items.length) {
                        this.selectItem(items[sIndex], true);
                    }
                }
            }
        } else if (!isOpen) {
            if (e.keyCode !== KeyCodes.ESC && e.keyCode !== KeyCodes.TAB && e.keyCode !== KeyCodes.ENTER) {
                this.open(e);
            }
        }
    };

    nextSelect(currentIndex) {
        const items = this.allItems();
        const itemsLen = items.length;
        for (let i = currentIndex + 1; i < itemsLen; i++) {
            if (!items[i].notSelectable) return i;
        }
        return currentIndex;
    }

    prevSelect(currentIndex) {
        const items = this.allItems();
        for (let i = currentIndex - 1; i >= 0; i--) {
            if (!items[i].notSelectable) return i;
        }
        return -1;
    }

    renderNotFoundItem() {
        return <li className="p_mini Combobox__not_found">{this.props.l('COMBOBOX.NOT_FOUND')}</li>;
    }

    isShowEnteredValue() {
        const p_ = this.props;
        const s_ = this.state;
        const inputValue = String(s_.inputValue || '').trim();
        const isSomethingEntered = inputValue !== '';
        const isEnteredValueUnique = !_.some(s_.itemsList, p_.equalPredicate.bind(null, inputValue));
        const isEnteredValueNotEqualSelectedItem = !(
            s_.selectedItem && s_.selectedItem[s_.displayingProp] === inputValue
        );
        const isShowMoreBtn = s_.hasNextPage && !p_.noRequestNextPage;
        const isFirstPage = s_.itemsList.length <= s_.pageSize;
        const isManyResultsAndNotFirstPage = !isShowMoreBtn || !isFirstPage;

        return (
            !s_.isLoadingNextPage &&
            p_.displayUseCustomFeature &&
            isSomethingEntered &&
            isEnteredValueNotEqualSelectedItem &&
            isManyResultsAndNotFirstPage &&
            isEnteredValueUnique
        );
    }

    render() {
        const p_ = this.props;
        const { l } = this.props;
        const s_ = this.state;
        const makeListItem = (item, index) => (
            <li
                key={index}
                ref={`item_${index}`}
                className={classNames({
                    Combobox__item: true,
                    Combobox__item_active: !s_.isSelectedEnteredValue && index === s_.sIndex,
                    Combobox__item_not_selectable: item.notSelectable,
                })}
                onClick={item.notSelectable ? null : this.selectItem.bind(null, item, true)}
            >
                {p_.renderListItem ? p_.renderListItem(item, index, this.state.inputValue) : item[s_.displayingProp]}
            </li>
        );

        const classNameSelect = p_.comboboxClassNames && p_.comboboxClassNames.select;
        const classNameOverlay = p_.comboboxClassNames && p_.comboboxClassNames.overlay;
        const classNameInput =
            (p_.comboboxClassNames && p_.comboboxClassNames.input) || p_.className || 'form-control biginput';
        const classNameOptions = p_.comboboxClassNames && p_.comboboxClassNames.options;
        const classNameLoading = p_.comboboxClassNames && p_.comboboxClassNames.loading;

        const load_more = l('COMBOBOX.CLICK_TO_LOAD_MORE');
        const load_next_page = l('COMBOBOX.LOAD_NEXT_PAGE');
        const error_loading_data = l('COMBOBOX.ERROR_LOADING_DATA');
        const isShowNotFoundItem =
            !s_.isLoadingNextPage && _.isEmpty(s_.itemsList) && !s_.message && !p_.displayUseCustomFeature;

        const NORMALIZED_INPUT_VALUE = String(s_.inputValue || '').trim();
        let inputValue = p_.isDisplayPlaceholder && !s_.isTyping ? '' : s_.inputValue;
        if (inputValue === null || inputValue === undefined) {
            inputValue = '';
        }

        const loadingItem = (
            <li ref="loadingIndicator" className={classNameLoading}>
                <span>{l('COMBOBOX.LOADING')}</span>
            </li>
        );

        const inputId = `${p_.seleniumid}-combobox-input`;

        return (
            <div className="Combobox" data-seleniumid={`${p_.seleniumid}-combobox`}>
                <div className="Combobox__input_container">
                    <label className='visuallyHidden' htmlFor={inputId}>Text</label>
                    <input
                        ref="input"
                        autoComplete="new-password"
                        className={classNames({[`${classNameInput}`]: true})}
                        id={inputId}
                        data-seleniumid={inputId}
                        disabled={p_.disabled}
                        onBlur={this.onInputBlur}
                        onChange={this.changeInputHandler}
                        onClick={this.open}
                        onFocus={p_.onFocus}
                        onKeyDown={e => {
                            p_.onBeforeKeyDown && p_.onBeforeKeyDown(e);
                            this.keyDown(e);
                        }}
                        placeholder={inputValue ? '' : p_.placeholder}
                        type="text"
                        value={inputValue}
                    />
                    <div
                        ref="comboboxArrow"
                        className="Combobox__arrow"
                        data-seleniumid={`${p_.seleniumid}-combobox-arrow`}
                        hidden={p_.hideArrow}
                        onClick={this.toggle}
                    />
                </div>
                <div className={classNames({Combobox__overlay: true, [`${classNameOverlay}`]: true })}>
                    <div
                        className={classNames({
                            Combobox__options: true,
                            Combobox__options_open:
                                s_.isOpen &&
                                this.isValidToSearch(s_.inputValue) &&
                                !s_.isLoadingNextPage &&
                                !_.isEmpty(s_.itemsList),
                            [`${classNameOptions}`]: true,
                        })}
                    >
                        {p_.options}
                    </div>
                    <ul
                        ref="overlayList"
                        className={classNames({
                            Combobox__select: true,
                            Combobox__select_open: s_.isOpen && this.isValidToSearch(s_.inputValue),
                            [`${classNameSelect}`]: true,
                            Combobox__select_loading: s_.isLoadingNextPage,
                        })}
                        data-seleniumid={`${p_.seleniumid}-combobox-overlay-list`}
                        onScroll={p_.endlessScroll ? this.onScroll : null}
                    >
                        {!p_.hideNoneValue
                            ? [this.nullItem].concat(s_.itemsList).map(makeListItem)
                            : s_.itemsList.map(makeListItem)}
                        {this.isShowEnteredValue() && (
                            <EnteredValueItem
                                ref="enteredValueItem"
                                customFeatureName={p_.customFeatureName}
                                inputValue={p_.enteredValueMapper(NORMALIZED_INPUT_VALUE)}
                                isFocused={s_.isSelectedEnteredValue}
                                onClick={() => this.selectText(NORMALIZED_INPUT_VALUE)}
                                useCustomText={l('COMBOBOX.USE_CUSTOM_FEATURE')}
                            />
                        )}
                        {!p_.endlessScroll && s_.hasNextPage && !s_.isLoadingNextPage && !p_.noRequestNextPage && (
                            <li
                                ref="loadMore"
                                className="Combobox__load_more"
                                data-seleniumid="combobox-load-more-btn"
                                onClick={this.onRequestNextPage}
                            >
                                <a>{load_more}</a>
                            </li>
                        )}
                        {s_.isLoadingNextPage && loadingItem}
                        {p_.endlessScroll && !s_.isLoadingNextPage && s_.hasNextPage && (
                            <li ref="fakeLoadingIndicator" className={classNameLoading}>
                                <span>{l('COMBOBOX.LOADING')}</span>
                            </li>
                        )}
                        {s_.isLoadingNextPage && !s_.itemsList && !s_.message && <li>{load_next_page}</li>}
                        {isShowNotFoundItem && this.renderNotFoundItem()}
                        {s_.message && (
                            <li
                                className="p_mini Combobox__not_found"
                                dangerouslySetInnerHTML={{ __html: s_.message }}
                            />
                        )}
                    </ul>
                </div>
                {s_.errorLoading && (
                    <div
                        ref="errorLoading"
                        className="text-danger"
                        dangerouslySetInnerHTML={{ __html: `${error_loading_data} ${s_.errorMessage}` }}
                    />
                )}
            </div>
        );
    }
}

Combobox.propTypes = {
    /** id for selenium tests */
    seleniumid: PropTypes.string.isRequired,

    /** Function for fetch data */
    getListDataPromise: PropTypes.func.isRequired,
    /** External handler for selecting an item */
    changeHandler: PropTypes.func.isRequired,

    /** Type of entered value */
    customFeatureName: PropTypes.string,
    /** Display or no type of entered value */
    displayUseCustomFeature: PropTypes.bool,
    /** Time delay before fetch items list */
    throttleWaitFetch: PropTypes.number,

    /** Function for render items of list */
    renderListItem: PropTypes.func,
    /** Mapper for entered value */
    enteredValueMapper: PropTypes.func,
    /** Compare input value with ... */
    equalPredicate: PropTypes.func,
    /** External handler for changing state of combobox - open/close */
    toggleIsOpen: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onBeforeKeyDown: PropTypes.func,

    /** Object with CSS classNames */
    comboboxClassNames: PropTypes.shape({
        select: PropTypes.string,
        overlay: PropTypes.string,
        options: PropTypes.string,
        loading: PropTypes.string,
        input: PropTypes.string,
    }),
    /** The property values of the items in the list that will be displayed */
    displayingProp: PropTypes.string,
    /** id of object nullItem */
    nullItemId: PropTypes.string,
    /** Value for nullItem[displayingProp] and undefinedItem[displayingProp] */
    nullItemName: PropTypes.string,
    /** id of selected item or selected item object */
    selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** Size of items list */
    pageSize: PropTypes.number,
    /** Min length of input value */
    requestMinLength: PropTypes.number,
    isOpen: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    isDisplayPlaceholder: PropTypes.bool,
    hideArrow: PropTypes.bool,
    /** Using or no none value */
    hideNoneValue: PropTypes.bool,
    emptyValueOnSelect: PropTypes.bool,
    notRestorePrevious: PropTypes.bool,
    /** Open/close combobox when input corrected or incorrected search value */
    toggleOnChange: PropTypes.bool,
    /** Disabled fetch next data */
    noRequestNextPage: PropTypes.bool,
    /** Auto fetch next data when items list is scrolled to end */
    endlessScroll: PropTypes.bool,

    /** ??? */
    options: PropTypes.node,
    error: PropTypes.node,

    comboboxInstance: PropTypes.func,
};

Combobox.defaultProps = {
    displayingProp: DEFAULT_DISPLAYING_PROP,
    displayUseCustomFeature: false,
    isOpen: undefined,
    nullItemName: 'none',
    pageSize: DEFAULT_PAGE_SIZE,
    throttleWaitFetch: DEFAULT_THROTTLE_WAIT_FETCH,
    enteredValueMapper: value => value,
    equalPredicate: (searchValue, menuItem) => menuItem[DEFAULT_DISPLAYING_PROP] === searchValue,
    onFocus: () => {},
    comboboxInstance: () => {},
};

export { Combobox };

export default withCodes(onClickOutside(Combobox), ID.COMBOBOX);
