import { ServiceError } from 'app/blocks/common/utils-errortypes';
import { DialogArgs } from 'app/blocks/dialog/dialog-list';
import addModalDialog from 'app/blocks/dialog/dialog.controller';

// exports for testing purposes
export const DEFAULT_ERROR_MESSAGE = 'Unable to process your request, due to system error';

export function prepareModalDialogParams(params: Record<string, any>, defaultMessage = '') {
    // because (new Error('message')).propertyIsNumerable('message') = false we need following hack
    if (params.message) {
        Object.defineProperty(params, 'message', { enumerable: true, value: params.message });
    }

    return {
        ...params,
        message: params.message || defaultMessage,
    };
}

function info(params: Omit<DialogArgs, 'type'>) {
    addModalDialog(close => ({
        ...params,

        onClose: close(params.onClose),
        type: 'info',
    }));
}

function success(params: Omit<DialogArgs, 'type'>) {
    addModalDialog(close => ({
        ...params,

        onClose: close(params.onClose),
        type: 'success',
    }));
}

function error(params: Omit<DialogArgs, 'type'>);
function error(error2: Error, params?: Omit<DialogArgs, 'type'>);
function error(error2: any, params?: any) {
    const paramss = {
        ...prepareModalDialogParams(error2, DEFAULT_ERROR_MESSAGE),
        ...params,
    };

    addModalDialog(close => ({
        ...paramss,

        onClose: close(paramss.onClose),
        type: 'error',
    }));
}

function addErrorMessageDialog(error2: ServiceError, onClose?) {
    if (['UNAUTHORIZED', 'AUTH_COMMUNICATION_ERROR'].indexOf(error2.code) > -1) {
        return; // do not show it to user. 'Your session has expired.' will be shown instead
    }

    addModalDialog(close => ({
        extendedMessage: error2.extendedMessage!,
        message: error2.message,
        onClose: close(onClose),
        refId: error2.refId,
        type: 'error',
    }));
}

function confirmation(params: Omit<DialogArgs, 'type'>);
function confirmation(error2: Error, params?: Omit<DialogArgs, 'type'>);
function confirmation(error2: any, params?: any) {
    const paramss = {
        // confirmStaticContent shows wrong content if prepareModalDialogParams(error2, DEFAULT_ERROR_MESSAGE)
        ...prepareModalDialogParams(error2),
        ...params,
    };

    addModalDialog(close => ({
        ...paramss,

        onApprove: close(paramss.onApprove),
        onIgnoreButtons: paramss.onIgnoreButtons ? close(paramss.onIgnoreButtons) : undefined,
        onReject: close(paramss.onReject),
        type: 'confirmation',
    }));
}

const modal: typeof addModalDialog = (...args) => {
    addModalDialog(...args);
};

export default {
    addErrorMessageDialog,
    confirmation,
    error,
    info,
    modal,
    success,
};
