import { isNonProd } from 'app/blocks/middleware/config';

function generateSite() {
    return {
        environment: isNonProd() ? 'non-prod' : 'prod',
        locale: navigator.language?.toLowerCase(),
    };
}

export default generateSite;
