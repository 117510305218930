import React from 'react';
import storage from 'app/blocks/common/storage';
import { getQueryParamSafe } from 'app/blocks/common/utils';

const urlParameters = {
    campaign: true,
    invitationArticleId: true,

    // fixme: double-check with business
    invitationToken: true,

    returnUrl: true,
};
const urlParametersList = Object.keys(urlParameters);

const WITH_PREFIX = key => `AS__url_param_${key}`;
const DEPRECATED_VALUE = '__AS__url_param__DEPRECATED__';

let cachedList = null;
let changesListeners = [];
let changesTimeout = null;
function invalidate() {
    cachedList = null;

    if (changesTimeout) {
        clearTimeout(changesTimeout);
    }
    changesTimeout = setTimeout(() => changesListeners.forEach(update => update({})), 0);
}

urlParametersList.forEach(key => storage.watch(WITH_PREFIX(key), invalidate));

export function updateUrlParam(key, value = '') {
    const oldValue = storage.get(WITH_PREFIX(key));
    if (oldValue === `${DEPRECATED_VALUE}-${value}` || value === oldValue) {
        return;
    }

    storage.set(WITH_PREFIX(key), value || '');
    invalidate();
}

export function getUrlParam(key) {
    const value = storage.get(WITH_PREFIX(key));
    return `${value}`.startsWith(DEPRECATED_VALUE) ? undefined : value;
}

export function invalidateUrlParam(key) {
    storage.remove(WITH_PREFIX(key));
    invalidate();
}

export function deprecateUrlParam(key) {
    const oldValue = getUrlParam(key);

    if (oldValue) {
        updateUrlParam(key, `${DEPRECATED_VALUE}-${oldValue}`);
    }
}

// inject params

function getParamsList() {
    if (!cachedList) {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        cachedList = urlParametersList.reduce((obj, key) => ({ ...obj, [key]: getUrlParam(key) }), {});
    }

    return cachedList;
}

export function useUrlParams() {
    const [, refreshUrlParams] = React.useState();
    React.useEffect(() => {
        changesListeners.push(refreshUrlParams);
        return () => {
            changesListeners = changesListeners.filter(v => v !== refreshUrlParams);
        };
    }, []);

    return getParamsList();
}

export default function withUrlParams(Component, serialize = false) {
    return serialize
        ? props => <Component {...useUrlParams()} {...props} />
        : props => <Component urlParams={useUrlParams()} {...props} />;
}

// parsing urls

let cachedParsedKey = null;
export function parseUrlParams(location) {
    const nativeSearch = window.location.search;
    const routerSearch = location.search;

    const cacheKey = `${routerSearch}${nativeSearch}`;
    if (cacheKey !== cachedParsedKey) {
        cachedParsedKey = cacheKey;

        const params = { ...getQueryParamSafe(nativeSearch, true), ...getQueryParamSafe(routerSearch, true) };

        Object.keys(params).forEach(key => {
            if (urlParameters[key]) {
                updateUrlParam(key, (params[key] || '').trim());
            }
        });
    }
}
