import cn from 'classnames';
import React from 'react';
import _ from 'underscore';
import { ID, withCodes } from 'app/blocks/common/codes';
import { eventContainsKey, KeyCode } from 'app/blocks/common/key-utils';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { getFocusableChildren } from 'app/blocks/common/utils';
import './Modal.scss';

type Props = {
    children: React.ReactNode;
    className?: string;
    hideCloseButtonInHeader?: boolean;
    id?: string;
    ignoreBackdropClicks?: boolean;
    ignoreEsc?: boolean;
    onClose?: () => void;
    onIgnoreButtons?: () => void;
    type?: string;
    modalHeading?: string;
};

class Modal extends React.Component<Props> {
    static defaultProps = {
        hideCloseButtonInHeader: false,
        ignoreBackdropClicks: false,
        ignoreEsc: false,
        modalHeading: '',
        type: 'custom',
    };

    modalDialog;

    componentDidMount() {
        this.modalDialog.focus();
    }

    close = () => {
        if (typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
    };

    onIgnoreButtons = () => {
        this.props.onIgnoreButtons();
    };

    onKeyDown = e => {
        if (eventContainsKey(e, KeyCode.ESC) && !this.props.ignoreEsc) {
            e.preventDefault();
            if (_.isFunction(this.props.onIgnoreButtons)) {
                this.onIgnoreButtons();
            } else {
                this.close();
            }
        } else if (eventContainsKey(e, KeyCode.TAB)) {
            const focusableNodes = getFocusableChildren(this.modalDialog);
            if (focusableNodes.length === 0) {
                // prevent modal dialog losing focus
                e.preventDefault();
            } else {
                const indexInFocusSequence = focusableNodes.indexOf(document.activeElement);

                // handle border cases
                if (e.shiftKey) {
                    // inverse sequence
                    if (indexInFocusSequence === -1 || indexInFocusSequence === 0) {
                        e.preventDefault();
                        focusableNodes[focusableNodes.length - 1].focus();
                    }
                } else if (indexInFocusSequence === -1 || indexInFocusSequence === focusableNodes.length - 1) {
                    e.preventDefault();
                    focusableNodes[0].focus();
                }
            }
        }
    };

    render() {
        const {
            children,
            className,
            hideCloseButtonInHeader,
            id,
            ignoreBackdropClicks,
            modalHeading,
            onIgnoreButtons,
            type,
        } = this.props;

        return (
            <div className={cn('modal', className)} id={id}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <div
                    className={`modal__backdrop id-${type}-modal-backdrop`}
                    onClick={ignoreBackdropClicks ? undefined : this.close}
                />
                <div
                    ref={modalDialog => {
                        this.modalDialog = modalDialog;
                    }}
                    className={`modal-dialog id-${type}-modal-layout`}
                    data-seleniumid={type && `modal-dialog-${type}`}
                    onKeyDown={this.onKeyDown}
                    role="presentation"
                    tabIndex={-1}
                >
                    {!hideCloseButtonInHeader && (
                        <div className="modal-close-container">
                            {modalHeading && (
                                <div className="modal-top-heading">
                                    <h2 className="main-title Header-Title">{modalHeading}</h2>
                                </div>
                            )}
                            <button
                                className={`modal-close id-${type}-close-top-button`}
                                data-seleniumid="modal-dialog-close-top-button"
                                onClick={_.isFunction(onIgnoreButtons) ? this.onIgnoreButtons : this.close}
                                type="button"
                            >
                                <SvgIcon.cancel2 iconTitle="Close" />
                            </button>
                        </div>
                    )}
                    <div className="modal-body-container">{children}</div>
                </div>
            </div>
        );
    }
}

export default withCodes(Modal, ID.DIALOG);
