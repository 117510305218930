import React, { Component } from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import MenuArticle from 'app/blocks/menu/__search/_item/__article/menu__search_item__article';
import MenuJournal from 'app/blocks/menu/__search/_item/__journal/menu__search_item__journal';
import MenuStatic from 'app/blocks/menu/__search/_item/__static/menu__search_item__static';
import * as middlewareSearch from 'app/blocks/middleware/search';
import { navigate } from 'app/route/history';
import Combobox from 'app/ui/form/Combobox';
import './menu__search.scss';

function getItemComponent(item: { type: keyof Search.Items }) {
    switch (item.type) {
        case 'article':
            return MenuArticle;
        case 'journal':
            return MenuJournal;
        case 'static':
            return MenuStatic;

        default:
            return null;
    }
}

class MenuSearch extends Component<{
    placeholder?: string;
}> {
    static defaultProps = {
        placeholder: '',
    };

    combobox = undefined;

    getDataForAutoComplete = async (query, size, offset) => {
        if ((query || '').trim()) {
            return (await middlewareSearch.search(query, { offset, size })).items;
        }

        return [];
    };

    onSelect = item => {
        const url = {
            article: item.doc.shareUrl,
            journal: item.doc.wolUrl,
            static: item.id,
        }[item.type];

        if (url) window.open((/^(https?:\/\/|\/)/.test(url) ? '' : '/') + url);
    };

    onSearch = () => {
        const cb = this.combobox;
        const value = (cb.state.inputValue || '').trim();
        if (value.length > 0) {
            navigate(`search?text=${encodeURIComponent(value)}`);
            cb.close();
        }
    };

    onFocus = () => {
        if (window.matchMedia('(max-width: 640px)').matches) {
            const element = document.querySelector('.menu-search input');
            setTimeout(() => element.scrollIntoView(), 250);
        }
    };

    renderSeeMore = () => (
        <LinkButton
            className="menu__search_see_more"
            data-seleniumid="menu-search-see-more-link"
            onClick={this.onSearch}
        >
            See more
        </LinkButton>
    );

    renderItem = (item: Search.Payload['items'][0]) => {
        const ItemComponent = getItemComponent(item);

        if (ItemComponent) {
            // @ts-ignore
            return <ItemComponent close={() => {}} item={item} />;
        }

        return <div>{`Not supported type: ${item.type}`}</div>;
    };

    render() {
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div className="menu__search_panel">
                <Combobox
                    changeHandler={this.onSelect}
                    comboboxClassNames={{
                        input: 'menu__search_Combobox__input form-control biginput action-anchor-search-header-input',
                        loading: 'menu__search_Combobox__loading',
                        options: 'menu__search_Combobox__options',
                        overlay: 'menu__search_Combobox__overlay',
                        select: 'menu__search_Combobox__select action-anchor-search-header-result',
                    }}
                    comboboxInstance={r => {
                        this.combobox = r;
                    }}
                    emptyValueOnSelect
                    error="Search is not available "
                    getListDataPromise={this.getDataForAutoComplete}
                    hideArrow
                    hideNoneValue
                    noRequestNextPage
                    notRestorePrevious
                    onEnter={this.onSearch}
                    onFocus={this.onFocus}
                    options={this.renderSeeMore()}
                    placeholder={this.props.placeholder}
                    renderListItem={this.renderItem}
                    requestMinLength={2}
                    seleniumid="menu-search"
                    toggleOnChange
                />
                <button
                    className="btn menu__search_Button action-anchor-search-header-submit"
                    data-seleniumid="menu-search__search-button"
                    onClick={this.onSearch}
                    type="button"
                >
                    <SvgIcon.search className="search-icon" iconTitle="Search Icon" />
                </button>
            </div>
        );
    }
}

export default MenuSearch;
