import getErrorMessage from './error';

function generateLogin(data) {
    const { step, validateResult } = data;

    if (!validateResult) {
        return data;
    }
    return {
        'error-message': getErrorMessage(validateResult, /^LOGIN_/, /_ERR_TEXT$/),
        step,
    };
}
export default generateLogin;
