import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';

function EnteredValueItem({ customFeatureName, inputValue, isFocused, onClick, useCustomText }) {
    const customFeatureTemplate = _.template(useCustomText);

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <li
            dangerouslySetInnerHTML={{
                __html: customFeatureTemplate({
                    element_type: customFeatureName,
                    entered_text: inputValue.bold(),
                }),
            }}
            className={cn('Combobox__selectEnteredValue bg-warning', { Combobox__item_active: isFocused })}
            data-seleniumid="combobox-item-use-custom-organization-btn"
            onClick={onClick}
        />
    );
}

EnteredValueItem.propTypes = {
    customFeatureName: PropTypes.string.isRequired,
    inputValue: PropTypes.string,
    isFocused: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    useCustomText: PropTypes.shape({}).isRequired,
};

EnteredValueItem.defaultProps = {
    inputValue: '',
    isFocused: false,
};

export default EnteredValueItem;
