import getErrorMessage from './error';

function generateRegistration(data) {
    const { step, validateResult } = data;

    if (!validateResult) {
        return data;
    }
    return {
        'error-message': getErrorMessage(validateResult, /^REGISTRATION_/, /_ERR_TEXT$/),
        step,
    };
}
export default generateRegistration;
