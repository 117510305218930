/* eslint-disable no-use-before-define, no-multi-assign */
import axios, { AxiosRequestConfig, AxiosProgressEvent } from 'axios';
import qs from 'qs';
import getPayloadOrFail from './http/parseResponse';

type RequestParams = {
    ajaxParams?: { doNotTrack: boolean; transactionId: string };
    detailed?: boolean;
    headers?: Record<string, string>;
    onUploadProgress?: (event: AxiosProgressEvent) => void;
    urlParams?: Record<string, any>;
};

const REAL_ROOT = '/api';

export function getRootUrl() {
    return REAL_ROOT;
}

function genericRequest(type: 'get' | 'post' | 'put' | 'delete') {
    return function request(url: string, body?: Record<string, any>, params: RequestParams = {}) {
        const { ajaxParams, detailed = false, headers, urlParams, ...others } = params;

        return getPayloadOrFail(
            axios({
                data: body,
                headers,
                metadata: ajaxParams,
                method: type.toLowerCase(),
                url: urlParams ? `${url}?${qs.stringify(urlParams, { arrayFormat: 'repeat' })}` : url,
                ...others,
            } as AxiosRequestConfig),
            detailed,
        );
    };
}

export const httpGET = (url: string, body?: Record<string, any>, params?: RequestParams) => {
    if (body) {
        return genericRequest('get')(url, undefined, { ...params, urlParams: { ...params?.urlParams, ...body } });
    }

    return genericRequest('get')(url, body, params);
};
export const httpPOST = genericRequest('post');
export const httpPUT = genericRequest('put');
export const httpDELETE = genericRequest('delete');

export function REST(URL: string = ''): {
    getRestUrl: () => string;
    GET: typeof httpGET;
    POST: typeof httpPOST;
    PUT: typeof httpPUT;
    DELETE: typeof httpDELETE;
} {
    return {
        DELETE: (url, ...args) => httpDELETE(`${REAL_ROOT}${URL}${url || ''}`, ...args),
        GET: (url, ...args) => httpGET(`${REAL_ROOT}${URL}${url || ''}`, ...args),
        POST: (url, ...args) => httpPOST(`${REAL_ROOT}${URL}${url || ''}`, ...args),
        PUT: (url, ...args) => httpPUT(`${REAL_ROOT}${URL}${url || ''}`, ...args),

        getRestUrl: () => `${REAL_ROOT}${URL || ''}`,
    };
}

const rootREST = REST();

export const GET = (REST.GET = rootREST.GET);
export const POST = (REST.POST = rootREST.POST);
export const PUT = (REST.PUT = rootREST.PUT);
export const DELETE = (REST.DELETE = rootREST.DELETE);
